/* src/components/Header.tsx */
import { Box, Flex, Container, Heading, Center } from '@chakra-ui/react';
import { FC } from 'react';

export const Header: FC = () => {
  return (
    <div>
      <Box bgColor="green.600">
        <Container maxW="container.lg">
          <Flex as="header" py="4" justifyContent="center" alignItems="center">
            <Heading
              color={'white'}
              as="h2"
              cursor="pointer"
              size="lg"
              isTruncated
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontSize={{ base: 'md', sm: 'lg', md: 'xl', lg: '2xl' }}
            >
              日本のチェコ公認 Tapsterスタンプラリー
            </Heading>
          </Flex>
          <Center
            color={'white'}
            isTruncated
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={{ base: 'md', sm: 'lg', md: 'xl', lg: '2xl' }}
          >
            目指せ全店制覇！
          </Center>
        </Container>
      </Box>
    </div>
  );
};
