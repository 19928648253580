import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";

interface InputNameFormProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputNameForm: React.FC<InputNameFormProps> = ({value, onChange}) => {
  // 名前フォームのバリデーション
  const isNameError = value === ''

  return (
    <FormControl isInvalid={isNameError} isRequired paddingTop={3}>
      <Input placeholder='なまえ' value={value} onChange={onChange}/>
      <FormErrorMessage>カードに表示する名前を1〜6文字以内に入力してください</FormErrorMessage>
    </FormControl>
  )
}

export default InputNameForm;
