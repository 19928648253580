import * as React from 'react';
import { useState, useRef, useEffect, createRef } from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  theme,
  Flex,
  Container,
  Button,
} from '@chakra-ui/react';
import { Header } from './Header';
import Konva from 'konva';
// react-konva からのインポート
import { Stage, Layer, Text } from 'react-konva';
import { useWindowSize } from 'react-use';
import {
  StampRallyImage,
  CheckboxList,
  CheckedItems,
  StampRallyCheck,
} from './StampRally';
import { Copyright } from './Copyright';
import InputNameForm from './InputNameForm';
import { currentDate } from './DateOutput';

const BASE_SIZE_X = 1472;
const BASE_SIZE_Y = 1041;
const BASE_SIZE_RATIO = BASE_SIZE_Y / BASE_SIZE_X;

export const App = () => {
  const { width } = useWindowSize();
  const [stageSizeHeight, setStageSizeHeight] = useState(0);
  const [stageSizeWidth, setStageSizeWidth] = useState(0);

  const ref = createRef<HTMLDivElement>();
  const stageRef = useRef<Konva.Stage>(null);

  useEffect(() => {
    setStageSizeHeight((ref.current?.clientWidth || 0) * BASE_SIZE_RATIO);
    setStageSizeWidth(ref.current?.clientWidth || 0);
  }, [width, ref]);

  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});

  const scaleX = stageSizeWidth / BASE_SIZE_X;
  const scaleY = stageSizeHeight / BASE_SIZE_Y;

  // 名前フォームの値を保持する
  const [name, setName] = useState('');

  const [imageDataUrl, setImageDataUrl] = useState('');

  const createImage = () => {
    if (stageRef.current) {
      const url = stageRef.current.toDataURL({
        pixelRatio: 3,
        mimeType: 'image/png',
        quality: 1,
      });
      setImageDataUrl(url);
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Container paddingTop={5} padding={7}>
        <Box textAlign="center" fontSize="xl" ref={ref}>
          <VStack spacing={0}>
            <Stage
              width={stageSizeWidth}
              height={stageSizeHeight}
              scaleX={scaleX}
              scaleY={scaleY}
              style={{ top: '8px' }}
              ref={stageRef}
              margin={30}
              justifyContent="center"
            >
              <Layer>
                <StampRallyImage />
                <Text text={name} fontSize={35} x={1185} y={930}></Text>
                <Text text={currentDate} fontSize={35} x={1205} y={850}></Text>
              </Layer>
              {/* StampRallyCheckはLayerで囲わないとスクリプトエラーになったので囲ってからExportしている */}
              <StampRallyCheck checkedItems={checkedItems} />
            </Stage>
            <br />
            <Text>🍺 使い方 🍺</Text>
            <Text>
              今まで行ったことのあるTapsterの店舗をチェックして「作成する」ボタンを押すと、スタンプカードが作成されます。
            </Text>
            <Flex>
              <Box
                width={stageSizeWidth}
                border="1px solid #ccc"
                borderRadius="4px"
                p="8px"
                maxW="100%"
                bg="white"
                margin={10}
              >
                <CheckboxList
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                />
                <InputNameForm
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Box paddingTop={5}>
                  <Button size={'lg'} colorScheme="blue" onClick={createImage}>
                    作成する
                  </Button>
                  {imageDataUrl && (
                    <img
                      src={imageDataUrl}
                      alt="スタンプラリー"
                      style={{ paddingTop: 30 }}
                    />
                  )}
                </Box>
              </Box>
            </Flex>
            <Copyright />
          </VStack>
        </Box>
      </Container>
    </ChakraProvider>
  );
};
