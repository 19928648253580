import React from 'react';
import { Text, Link, Box } from '@chakra-ui/react';

export const Copyright = () => {
  return (
    <Box fontSize={{ base: 'md', sm: 'lg', md: 'xl', lg: '2xl' }}>
      <Text fontSize="sm" color="gray.600" textAlign="center">
        {'© '}
        <Link color="teal.500" href="https://tapster-stamprally.vtryo.me/">
          日本のチェコ公認 Tapsterスタンプラリー
        </Link>{' '}
        {new Date().getFullYear()}.
        <br />
        {'developed by '}
        <Link color="teal.500" href="https://www.instagram.com/vtryo_ryo/">
          VTRyo
        </Link>
      </Text>
    </Box>
  );
};
