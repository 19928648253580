import React from 'react';
import { Checkbox, Box, Text, Flex } from '@chakra-ui/react';
import { Layer, Image as KonvaImage, Label } from 'react-konva';

import useImage from 'use-image';

interface StoreItem {
  id: number;
  name: string;
  x: number;
  y: number;
}

const tapsterStores = [
  'Brasserie Beer Blvd.',
  'ニユートーキヨー有楽町電気ビル',
  'ビアとら',
  'オーデン',
  'フラムドール',
  'WHY BEER?',
  'VAPEUR',
  'クシクモ',
  "eric'S by EricTrochon",
  'ぴるぜん',
  'レッドドラゴン',
  '黒日果',
  'ひょうたんや',
  '鉄板串焼 炎魔',
  'THE SHIP',
];

// { id: 1, name: 'Brasserie Beer Blvd.', x: 160, y: 200 }の形でハッシュを作成する
const createStoreData = () => {
  const numItems = tapsterStores.length; // アイテムの総数
  const xStart = 145; // X 座標の開始値
  const xIncrement = 340; // X 座標の増分
  const yStart = 200; // Y 座標の開始値
  const yIncrement = 210; // Y 座標の増分
  const xResetInterval = 4; // X 座標リセット間隔
  const yChangeInterval = 4; // Y 座標変更間隔

  const storeData: StoreItem[] = [];
  for (let i = 0; i < numItems; i++) {
    const x = xStart + (i % xResetInterval) * xIncrement;
    const y = yStart + Math.floor(i / yChangeInterval) * yIncrement;
    const name = tapsterStores[i];
    storeData.push({ id: i + 1, name, x, y });
  }

  return storeData;
};

export interface CheckedItems {
  [key: string]: boolean;
}

export interface CheckboxListProps {
  checkedItems: CheckedItems;
  setCheckedItems: React.Dispatch<React.SetStateAction<CheckedItems>>;
}

export const CheckboxList: React.FC<CheckboxListProps> = ({
  checkedItems,
  setCheckedItems,
}) => {
  const StoreData = createStoreData();

  const handleCheck = (itemId: number) => {
    setCheckedItems((prev: CheckedItems) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  return (
    <Flex wrap="wrap" gap={2}>
      {StoreData.map((item) => (
        <Box
          minWidth={'250px'}
          key={item.id}
          flexBasis={{ base: '100%', sm: '50%', md: '33.33%', lg: '25%' }}
          p={3}
        >
          <Flex>
            <Label style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                mr="2"
                isChecked={checkedItems[item.id]}
                onChange={() => handleCheck(item.id)}
              />
              <Text fontSize={12}>{item.name}</Text>
            </Label>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};

interface StampRallyImgProps {
  checkedItems: CheckedItems;
}

interface CheckImgProps {
  x: number;
  y: number;
}

export const StampRallyImage: React.FC = () => {
  const [tapsterImage] = useImage('./tapster_contents.png');
  return <KonvaImage image={tapsterImage} />;
};

export const StampRallyCheck: React.FC<StampRallyImgProps> = ({
  checkedItems,
}) => {
  const StoreData = createStoreData();

  const CheckImg: React.FC<CheckImgProps> = ({ x, y }) => {
    const [image] = useImage('./checkmark.png');
    return <KonvaImage image={image} x={x} y={y} opacity={0.6} />;
  };

  // ここリアルタイムでチェックボックスを反映させる必要は必ずしもない。Debug用で使う感じで
  return (
    <Layer>
      {Object.entries(checkedItems).map(([id, isChecked]) => {
        if (isChecked) {
          const item = StoreData.find((item) => item.id.toString() === id);
          if (!item) return null;

          return <CheckImg key={id} x={item.x} y={item.y} />;
        }
        return null;
      })}
    </Layer>
  );
};
