import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>Tapsterスタンプラリー</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta property="og:title" content="Tapsterスタンプラリー" />
        <meta
          property="og:description"
          content="チェコ公認日本Tapsterの訪問済み店舗をスタンプカード風に可視化します。"
        />
        <meta property="twitter:title" content="Tapsterスタンプラリー" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:image" content="https://tapster-stamprally.vtryo.me/ogimage.png" />
        <meta
          property="twitter:description"
          content="チェコ公認日本Tapsterの訪問済み店舗をスタンプカード風に可視化します。"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
